import React, { useEffect } from 'react';
import { useParams, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../Layout';
import SEOHandler from '../SEOHandler';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

const LanguageWrapper = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const location = useLocation();
  const validLanguages = ['tw', 'cn', 'en'];
  
  // 在服務器端渲染時，設置語言
  if (isServer && lang && validLanguages.includes(lang)) {
    i18n.changeLanguage(lang);
  }
  
  // 客戶端語言處理
  useEffect(() => {
    // 在服務器端渲染時跳過
    if (isServer) return;
    
    // 如果語言參數有效，則設置語言
    if (lang && validLanguages.includes(lang) && i18n.language !== lang) {
      // 更改語言設定
      i18n.changeLanguage(lang);
      
      // 更新HTML的lang屬性，確保搜索引擎和輔助技術正確識別頁面語言
      const htmlLang = lang === 'tw' ? 'zh-TW' : 
                      lang === 'cn' ? 'zh-CN' : 'en';
      document.documentElement.lang = htmlLang;
      
      // 儲存當前語言到 localStorage，確保刷新後仍然使用正確的語言
      try {
        localStorage.setItem('i18nextLng', lang);
      } catch (e) {
        console.error('Error setting localStorage:', e);
      }
      
      // 滾動到頁面頂部
      window.scrollTo(0, 0);
    }
  }, [lang, i18n, validLanguages]);
  
  // 始終返回相同的布局，確保服務器端和客戶端渲染一致
  return (
    <Layout>
      {/* 添加 SEOHandler 組件，處理客戶端導航時的SEO標籤 */}
      {!isServer && <SEOHandler />}
      <Outlet />
    </Layout>
  );
};

export default LanguageWrapper;
